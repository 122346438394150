@charset "utf-8";

// Bulma
@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";

$primary: #34ac46;
$link: $primary;

$divider-margin-inner-size: 0;

$body-background-color-dark: #1d1e1c;

@import "../node_modules/bulma/bulma";
@import "../node_modules/bulma-prefers-dark/bulma-prefers-dark";
@import "~@creativebulma/bulma-divider";

html {
  overflow-y: auto;
}

.has-hover-text-white:hover {
  color: $white;
}

.preparation img {
  margin-top: 0.5rem;
}

.navMenu {
  background-color: $grey-lighter;
}
@media (prefers-color-scheme: dark) {
  .navMenu {
    background-color: $black-bis;
  }

  .is-inverted {
    filter: invert(1);
  }
}

@media (max-width: 768px) {
  .is-reversed-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .row-even {
    background-color: darken($body-background-color, 10);
  }

  @media (prefers-color-scheme: dark) {
    .row-even {
      background-color: lighten($body-background-color-dark, 5);
    }
  }
}
